import React, { useEffect, useRef, useState } from "react";
import * as Plot from "@observablehq/plot";
import "./GraphStyle.css";

export default function StopsRating() {
  const [stops, setStops] = useState([]);

  const plotRef = useRef();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("https://api.austin-wheels.me/transport");
        const data = await response.json();
        setStops(data);
        console.log(data)
      } catch (error) {
        console.error("Error fetching stops data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (stops.length > 0) {
  
      const graph = Plot.plot({
        x: { grid: false, label: "Stop" },
        y: { grid: true, label: "Rating" },
        color: { legend: true, scheme: "YlGnBu" },
        marks: [
          Plot.dot(stops, {
            x: "name",
            y: "rating",
            stroke: "rating",
            tip: true,
            r: 3,
            fill: "rating",
            sort: {x: "y"}
          })
        ]
      });
  
      plotRef.current.append(graph);
      console.log("Graph created:", graph);
  
      return () => {
        // Cleanup code if needed
        graph.remove();
      };
    }
  }, [stops]);

  return (<div ref={plotRef} className='BarGraph'/>);
}

