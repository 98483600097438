import './Card.css'; // Import the CSS file
import { Link } from 'react-router-dom'
import React, { useState } from 'react';


const LocationCard = ({ locationData }) => {
  const {
    name,
    zipCode,
    city,
    locationType,
    rating,
    daysOpen,
    id,
    page,
    backgroundImageUrl, // URL to the background image
  } = locationData;
  const route = `/locations/${id}`;
  const [imageError, setImageError] = useState(false);
  const defaultImageUrl = 'https://webstockreview.net/images/location-clipart-geolocation-2.png'; // Replace with your default image URL
  const handleImageError = () => {
    setImageError(true);
  };
  return (
    <div className="card-container">
      <Link to={route} className="hover-color"> 
        <div className="card-details">
          <img
            className='card-img'
            src={imageError ? defaultImageUrl : backgroundImageUrl}
            alt='image'
            onError={handleImageError} // Set the onError event handler
          />
          <h3><strong>{name}</strong></h3>
          <p><strong>Zip Code:</strong> {zipCode}</p>
          <p><strong>City:</strong> {city}</p>
          <p><strong>Type:</strong> {locationType}</p>
          <p><strong>Rating:</strong> {rating}</p>
          <p><strong>Days Open:</strong> {daysOpen}</p>
          <div className="learn-more-btn">More...</div>
        </div>
      </Link>
    </div>
  );
};

export default LocationCard;
