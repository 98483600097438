import React, { useState, useEffect } from 'react';
import TransportationCard from '../components/TransportationCard';
import './Page.css';
import axios from 'axios';
import LoadingAnimation from './LoadingAnimation';

function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

function highlightText(text, highlight) {
  const strText = String(text);
  const safeHighlight = escapeRegExp(highlight);
  const parts = strText.split(new RegExp(`(${safeHighlight})`, 'gi'));
  return (
    <>
      {parts.map((part, i) =>
        part.toLowerCase() === highlight.toLowerCase() ? (
          <span key={i} className="highlight">{part}</span>
        ) : (
          part
        )
      )}
    </>
  );
}

const Stops = () => {
  const [stops, setStops] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null); // State to store error
  const [openDropdown, setOpenDropdown] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(() => {
    // Retrieve the current page number from local storage or set it to 1 if not available
    return parseInt(localStorage.getItem('currentPage')) || 1;
  });
  const [searchInput, setSearchInput] = useState('');
  const [submitSearch, setSubmitSearch] = useState('');
  const handleSearchSubmit = (event) => {
    event.preventDefault();
    if (searchInput.trim()) {
      setSubmitSearch(searchInput);
      setSearchInput('');
    }
  };
  const [filter, setFilter] = useState('');
  const [sortZip, setSortZip]= useState("Off");
  const [sortName, setSortName]= useState("Off");
  const [sortRating, setSortRating]= useState("Off");
  const itemsPerPage = 10; // Number of items to display per page
  const [dropdownOptions, setDropdownOptions] = useState({
    city: ["Bastrop", "Burnet", "Dripping Springs", "Elgin", "Kyle", "Taylor", "Cedar Park", "Georgetown", "Pflugerville", "Hutto", "Jarrel", "Leander", "Liberty Hill",
    "Lockhart", "Marble Falls", "Manor", "San Marcos", "Buda", "Round Rock", "Austin"],
    zip: ["On", "Off"],
    name: ["On", "Off"],
    rating: ["On", "Off"],
    type: ["Car Dealer", "Car Repair", "Car Rental", "Bus Stop", "Transit"],
  });

  useEffect(() => {
    let request = "https://api.austin-wheels.me/transport";
    console.log(submitSearch);
    if (filter != '' || submitSearch != '') {
      if (submitSearch != '') {
        request = "https://api.austin-wheels.me/transport?search=" + submitSearch;
      } else {
        request = "https://api.austin-wheels.me/transport?search=" + filter;
      }
      if (sortZip === 'On') {
        request += "&sort=zip";
      } else if (sortName === 'On') {
        request += "&sort=name"
      } else if (sortRating === 'On') {
        request += "&sort=rating"
      }
    } else {
      if (sortZip === 'On') {
        request = "https://api.austin-wheels.me/transport?sort=zip";
      } else if (sortName === 'On') {
        request = "https://api.austin-wheels.me/transport?sort=name"
      } else if (sortRating === 'On') {
        request = "https://api.austin-wheels.me/transport?sort=rating"
      }
    }
    axios.get(request)
      .then(response => {
        setStops(response.data);
        setDropdownOptions(prevOptions => ({
          ...prevOptions,
        }));
        setLoading(false);
      })
      .catch(error => {
        console.error("There was an error fetching transportation stops:", error);
        setError(error);
        setLoading(false);
      });
  }, [filter, sortZip, sortName, sortRating, submitSearch]);

  // Save the current page number to local storage whenever it changes
  useEffect(() => {
    localStorage.setItem('currentPage', currentPage.toString());
  }, [currentPage]);

  // Display error message if there is an error
  if (error) {
    return (
      <div className='page'>
        <p className="error-text" style={{ paddingTop: '30px', fontSize: '30px'}}>
          Error: Unable to fetch data from the API.</p>
      </div>
    );
  }

  // Calculate the range of stops to display on the current page
  const indexOfLastStop = currentPage * itemsPerPage;
  const indexOfFirstStop = indexOfLastStop - itemsPerPage;
  const currentStops = stops.slice(indexOfFirstStop, indexOfLastStop);

  // Handle page change
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleDropdownClick = (option) => {
    setIsDropdownOpen((prevState) => !prevState);
    setOpenDropdown(openDropdown === option ? null : option);
  };

  const handleSortChange = (e, v) => {
    if (e === "city" || e === "type") {
      setFilter(v);
      setSubmitSearch('');
    } else if (e === "zip"){
      setSortZip(v);
      setSortName("Off");
      setSortRating("Off");
    } else if (e === "name") {
      setSortName(v);
      setSortZip("Off");
      setSortRating("Off");
    } else if (e === "rating") {
      setSortRating(v);
      setSortZip("Off");
      setSortName("Off");
    }
  };

  // Generate pagination buttons
  const generatePaginationButtons = () => {
    const totalPages = Math.ceil(stops.length / itemsPerPage);
    const pageButtons = [];
    const maxButtonsToShow = 10; // Number of pagination buttons to show at a time

    // Display "Prev" button
    if (currentPage > 1) {
      pageButtons.push(
        <button
          key="prev"
          className="prev-next-button"
          onClick={() => handlePageChange(currentPage - 1)}
        >
          Prev
        </button>
      );
    }

    // Display pagination buttons based on the current page
    const startPage = Math.max(1, Math.min(currentPage - Math.floor(maxButtonsToShow / 2), totalPages - maxButtonsToShow + 1));
    const endPage = Math.min(totalPages, startPage + maxButtonsToShow - 1);

    for (let page = startPage; page <= endPage; page++) {
      pageButtons.push(
        <button
          key={page}
          className={`page-button ${currentPage === page ? 'current-page' : ''}`}
          onClick={() => handlePageChange(page)}
        >
          {page}
        </button>
      );
    }

    // Display "Next" button
    if (currentPage < totalPages) {
      pageButtons.push(
        <button
          key="next"
          className="prev-next-button"
          onClick={() => handlePageChange(currentPage + 1)}
        >
          Next
        </button>
      );
    }

    return pageButtons;
  };

  if (loading) {
    return <LoadingAnimation/>
  }

  return (
    <div className='page'>
      <div className='search-container'>
        <form onSubmit={handleSearchSubmit}>
            <input 
              type='text' 
              placeholder='Search...' 
              className='search-input'
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
          <button type='submit' className='search-button'>Go</button>
        </form>
      </div>
      <div className="dropdown-container">
        {Object.keys(dropdownOptions).map((option) => (
          <div className="dropdown" key={option}>
            <div className={`dropdown-button ${openDropdown === option ? 'open' : ''}`} onClick={() => handleDropdownClick(option)}>
              {option.charAt(0).toUpperCase() + option.slice(1)} {openDropdown === option && isDropdownOpen ? '▲' : '▼'}
            </div>
            {openDropdown === option && (
              <div className="dropdown-options">
                {dropdownOptions[option].map((value) => (
                  <div className="option" key={value} onClick={() => handleSortChange(option, value)}>
                    {value}
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>

      <p className="title-text custom-title">Transportation Stops</p>
      <p>Displaying {currentStops.length} out of {stops.length} results</p>
      <div className='page-container'>
        {currentStops.map(transportation => {
          const mappedTransportationData = {
            stop: highlightText(transportation.name, submitSearch),
            zipCode: highlightText(transportation.zip, submitSearch),
            city: highlightText(transportation.city, submitSearch),
            type: highlightText(transportation.types.join(", "), submitSearch),
            rating: highlightText(transportation.rating, submitSearch),
            backgroundImageUrl: transportation.first_photo.photo_reference,
            id: transportation.id,
            page: `/transport/${transportation.place_id}` // This is a placeholder. Adjust the link generation as needed.
          };
  
          return <TransportationCard key={transportation.place_id} transportationData={mappedTransportationData} />;
        })}
      </div>
      <div className="pagination">
        {generatePaginationButtons()}
      </div>
    </div>
  );
}

export default Stops;
