import React, { useState } from 'react';
import './MiniCard.css'; // Import your CSS file
import { Link } from 'react-router-dom'

const Card = ({ name, imageUrl, route}) => {
  const [imageError, setImageError] = useState(false);
  const defaultImageUrl = 'https://webstockreview.net/images/location-clipart-geolocation-2.png'; // Replace with your default image URL
  const handleImageError = () => {
      setImageError(true);
  };
  return (
    <div id="card">
      <img id="card-image" className='card-img'
        src={imageError ? defaultImageUrl : imageUrl}
        alt='image'
        onError={handleImageError}
      />
      <div id="card-text">
        <p id="card-name">{name}</p>
        <Link to={route} className="hover-color"><button className='learn-more-btn'>More...</button></Link>
      </div>
    </div>
  );
};

export default Card;
