import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './StopInstance.css'; // Import the CSS file for styling
import MiniCard from '../../components/MiniCard';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import axios from 'axios';
import LoadingAnimation from '../LoadingAnimation';

function mapRatingToStars(rating) {
  const numStars = Math.round(parseFloat(rating));
  return "⭐".repeat(numStars);
}

const StopInstance = () => {
  const { id } = useParams();
  const [stop, setStop] = useState(null);
  const [loading, setLoading] = useState(true);
  const [locations, setLocations] = useState([]);
  const [zipCodes, setZipCodes] = useState([]);

  useEffect(() => {
    axios
      .get(`https://api.austin-wheels.me/transport/${id}`)
      .then((response) => {
        setStop(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("There was an error fetching transportation stops:", error);
        setLoading(false);
      });
  }, [id]);

  useEffect(() => {
    axios
      .get(`https://api.austin-wheels.me/locations`)
      .then((response) => {
        // Filter locations by zip code
        const filteredLocations = response.data.filter(
          (location) => location.zip === stop.zip
        );
        setLocations(filteredLocations);
      })
      .catch((error) => {
        console.error("There was an error fetching locations:", error);
      });
    axios
      .get(`https://api.austin-wheels.me/zipcodes`)
      .then((response) => {
        // Fetch all zip codes without filtering
        setZipCodes(response.data);
      })
      .catch((error) => {
        console.error("There was an error fetching zip codes:", error);
      });
  }, [stop]);

  function getDayName(dayNumber) {
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    return daysOfWeek[dayNumber];
  }

  function formatTime(time) {
    // Assuming time is in 24-hour format without leading zeros
    const formattedTime = `${time.slice(0, 2)}:${time.slice(2)}`;
    return formattedTime;
  }

  const settings = {
    infinite: true,
    slidesToShow: 3, // Number of slides to show at a time
    slidesToScroll: 1, // Number of slides to scroll at a time
  };

  return (
    <div className="stop-instance-container">
      {loading ? (
        <LoadingAnimation/>
      ) : (
        stop && (
          <div className='instance'>
            <h1 className="stop-name">{stop.name}</h1>
            <div className="stop-info">
              <div className="stop-image">
                <img src={stop.first_photo.photo_reference} alt="Stop" />
              </div>
              <div className="stop-details">
                <h2>Stop Details</h2>
                <p>
                  <strong>City:</strong> {stop.city}
                </p>
                <p>
                  <strong>Address:</strong> {stop.formatted_address}
                </p>
                <p>
                  <strong>Zip Code:</strong> {stop.zip}
                </p>
                <p>
                  <strong>Rating:</strong> {stop.rating}
                </p>
                <p>
                  <strong>Wheelchair Accessible:</strong>{" "}
                  {stop.wheelchair_accessible ? "Yes" : "No"}
                </p>
              </div>
            </div>

            <div className="location-hours">
              <h2>Opening Hours</h2>
              <ul>
                {stop.opening_hours_periods.map((period, index) => (
                  <li key={index}>
                    <strong>{getDayName(period.open.day)}:</strong> {formatTime(period.open.time)} - {formatTime(period.close.time)}
                  </li>
                ))}
              </ul>
            </div>

            <div className="location-reviews">
              <h2>Reviews</h2>
              <ul>
                {stop.reviews.map((review, index) => (
                  <li className='review-item' key={index}>
                    <h3>{review.author_name}</h3>
                    <p>{review.text}</p>
                    <div className="review-rating">{mapRatingToStars(review.rating)}</div>
                  </li>
                ))}
              </ul>
            </div>
            
            {/* Display MiniCards for Close Locations */}
            <div className="stop-nearby">
              <h2>Nearby Locations</h2>
              <Slider {...settings}>
                {locations.map((location, index) => (
                  <div key={index} className="mini-card">
                    <MiniCard name={location.name} imageUrl={location.first_photo.photo_reference} route={`/locations/${location.id}`}/>
                  </div>
                ))}
              </Slider>
            </div>

            {/* Display MiniCards for Nearby Zip Codes */}
            <div className="stop-nearby">
              <h2>Nearby Zip Codes</h2>
              <Slider {...settings}>
                {zipCodes.map((zipcode, index) => (
                  <div key={index} className="mini-card">
                    <MiniCard name={zipcode.postal_code} imageUrl={zipcode.photo_reference_1} route={`/zipcodes/${zipcode.id}`}/>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default StopInstance;
