import React from 'react';
import './App.css';
import Navbar from './components/Navbar';
import LandingPage from './pages/LandingPage'
import ZipCodes from './pages/ZipCodes'
import Locations from './pages/Locations'
import Stops from './pages/Stops'
import About from './pages/About'
import LocationInstance from './pages/instancePages/LocationInstance'
import ZipInstance from './pages/instancePages/ZipInstance'
import StopInstance from './pages/instancePages/StopInstance'
import Visuals from './pages/Visuals'
import ProviderVisuals from './pages/ProviderVisuals'
import GeneralSearch from './pages/GeneralSearch'

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

function App() {
  // Sample location data
    return (
      <Router>
        <Navbar/>
        <Routes>
          <Route path='/' element={<LandingPage/>}/>
          <Route path='/Zip' element={<ZipCodes/>}/>
          <Route path='/Locations' element={<Locations/>}/>
          <Route path='/Stops' element={<Stops/>}/>
          <Route path='/About' element={<About/>}/>
          <Route path="/locations/:id" element={<LocationInstance/>} />
          <Route path="/zipcodes/:id" element={<ZipInstance/>} />
          <Route path="/stops/:id" element={<StopInstance/>} />
          <Route path="/GeneralSearch" element={<GeneralSearch/>}/>
          <Route path='/Visuals' element={<Visuals/>}/>
          <Route path='/ProviderVisuals' element={<ProviderVisuals/>}/>
        </Routes>
      </Router>
    );
}

export default App;
