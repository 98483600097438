import React, { useEffect, useRef, useState } from "react";
import * as Plot from "@observablehq/plot";
import "./GraphStyle.css";

export default function FireAgencyMap() {
  const [agencies, setAgencies] = useState([]);

    const plotRef = useRef();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch("https://api.californiawildfires.me/allFacilities");
                const data = await response.json();
                setAgencies(data);
                console.log(data)
            } catch (error) {
                console.error("Error fetching county data:", error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
      if (agencies.length > 0) {
          const graph = Plot.plot({
              x: {grid: true},
              y: {grid: true},
              color: {legend: false, scheme: "category10", reverse: true},
              marks: [
                  Plot.dot(agencies, {
                      x: "lon",
                      y: "lat",
                      tip: true,
                      r: 4,
                      fill: "city"
                  })
              ]
          })

          plotRef.current.append(graph)
        //   console.log("Graph created:", graph);

          return () => {
              graph.remove();
          };
      }
  }, [agencies]);

  return (
    <div ref={plotRef} className="BarGraph"/>
  );
};
