import React, { useEffect, useRef, useState } from "react";
import * as Plot from "@observablehq/plot";
import "./GraphStyle.css";

export default function ZipCodeGraph() {
  const [zips, setZips] = useState([]);

  const plotRef = useRef();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("https://api.austin-wheels.me/zipcodes");
        const data = await response.json();
        setZips(data);
        console.log(data)
      } catch (error) {
        console.error("Error fetching zip data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (zips.length > 0) {
      const graph = Plot.plot({
        x: { grid: true, label: "Zip Code" },
        y: { grid: true, label: "# of Locations" },
        color: { legend: true, scheme: "Category10" },
        marks: [
          Plot.dot(zips, {
            x: "postal_code",
            y: "location_count",
            stroke: "postal_code",
            tip: true,
            r: 4,
            fill: "postal_code"
          })
        ]
      })

      plotRef.current.append(graph)
      console.log("Graph created:", graph);

      return () => {
        // Cleanup code if needed
        graph.remove();
      };
    }
  }, [zips]);

  return (<div ref={plotRef} className='BarGraph'/>);
}

