import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './ZipInstance.css';
import axios from 'axios';
import MiniCard from '../../components/MiniCard';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import LoadingAnimation from '../LoadingAnimation';

const ZipInstance = () => {
  const { id } = useParams();
  const [zipInfo, setZipInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [transportationStops, setTransportationStops] = useState([]);
  const [locations, setLocations] = useState([]);


  useEffect(() => {
    // Fetch zip code information
    axios
      .get(`https://api.austin-wheels.me/zipcodes/${id}`)
      .then((response) => {
        setZipInfo(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("There was an error fetching zip code information:", error);
        setLoading(false);
      });
  }, [id]);

  useEffect(() => {
    // Fetch transportation stops
    if (zipInfo) {
      axios
        .get(`https://api.austin-wheels.me/transport`)
        .then((response) => {
          const filteredStops = response.data.filter(
            (stop) => stop.zip === zipInfo.postal_code
          );
          setTransportationStops(filteredStops);
        })
        .catch((error) => {
          console.error("There was an error fetching transportation stops:", error);
        });
    }
  }, [zipInfo]);

  useEffect(() => {
    // Fetch locations
    if (zipInfo) {
      axios
        .get(`https://api.austin-wheels.me/locations`)
        .then((response) => {
          const filteredLocations = response.data.filter(
            (location) => location.zip === zipInfo.postal_code
          );
          setLocations(filteredLocations);
        })
        .catch((error) => {
          console.error("There was an error fetching locations:", error);
        });
    }
  }, [zipInfo]);

  const settings = {
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    variableWidth: true,
  };

  return (
    <div className="zip-instance-container">
      {loading ? (
        <LoadingAnimation/>
      ) : (
        zipInfo && (
          <div className="zip-info">
            <h1>Zip Code: {zipInfo.postal_code}</h1>
            <p>City: {zipInfo.city}</p>
            <p>State: {zipInfo.state}</p>
            <p>Country Code: {zipInfo.country_code}</p>
            {/* Display other zip code information as needed */}
            <img src={zipInfo.photo_reference_1} alt="Zip Code" />
            <img src={zipInfo.photo_reference_2} alt="Zip Code Map" />

            {/* Display MiniCards for Transportation Stops */}
            <h2>Nearby Transportation Stops</h2>
            <Slider {...settings}>
              {transportationStops.map((transportStop) => (
                <MiniCard
                  key={transportStop.id}
                  name={transportStop.name}
                  imageUrl={transportStop.first_photo.photo_reference}
                  route={`/stops/${transportStop.id}`}
                />
              ))}
            </Slider>

            {/* Display MiniCards for Locations */}
            <h2>Nearby Locations</h2>
            <Slider {...settings}>
              {locations.map((location) => (
                <MiniCard
                  key={location.id}
                  name={location.name}
                  imageUrl={location.first_photo.photo_reference}
                  route={`/locations/${location.id}`}
                />
              ))}
            </Slider>
          </div>
        )
      )}
    </div>
  );
};

export default ZipInstance;
