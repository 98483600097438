import React, { useEffect, useState } from 'react';
import { Route, useParams } from 'react-router-dom';
import './LocationInstance.css';
import MiniCard from '../../components/MiniCard'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import axios from 'axios';
import LoadingAnimation from '../LoadingAnimation';

function mapRatingToStars(rating) {
    const numStars = Math.round(parseFloat(rating));
    return "⭐".repeat(numStars);
}

const LocationInstance = () => {
  const { id } = useParams();
  const [location, setLocation] = useState(null);
  const [loading, setLoading] = useState(true);
  const [transportationStops, setTransportationStops] = useState([]);
  const [zipCodes, setZipCodes] = useState([]);
  const [miniCardsLoading, setMiniCardsLoading] = useState(true);


  useEffect(() => {
    axios
      .get(`https://api.austin-wheels.me/locations/${id}`)
      .then((response) => {
        setLocation(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("There was an error fetching location:", error);
        setLoading(false);
      });
  }, [id]);

  useEffect(() => {
    if (location) {
      axios
        .get(`https://api.austin-wheels.me/transport`)
        .then((response) => {
          // Filter transportation stops by zip code
          const filteredStops = response.data.filter(
            (stop) => stop.zip === location.zip
          );
          setTransportationStops(filteredStops);
          setMiniCardsLoading(false);
        })
        .catch((error) => {
          console.error("There was an error fetching transportation stops:", error);
          setMiniCardsLoading(false);
        });

      axios
        .get(`https://api.austin-wheels.me/zipcodes`)
        .then((response) => {
          // Filter zip codes by city name
          setZipCodes(response.data);
        })
        .catch((error) => {
          console.error("There was an error fetching zip codes:", error);
        });
    }
  }, [location]);

  function getDayName(dayNumber) {
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    return daysOfWeek[dayNumber];
  }

  function formatTime(time) {
    // Assuming time is in 24-hour format without leading zeros
    const formattedTime = `${time.slice(0, 2)}:${time.slice(2)}`;
    return formattedTime;
  }

  const settings = {
    infinite: true,
    slidesToShow: 3, // Number of slides to show at a time
    slidesToScroll: 1, // Number of slides to scroll at a time
  };

  return (
    <div className="location-instance-container">
      {loading ? (
       <LoadingAnimation/>
      ) : (
        location && (
          <div className='instance'>
            <h1 className="location-name">{location.name}</h1>
            <div className="location-info">
              <div className="location-image">
                <img src={location.first_photo.photo_reference} alt="Location" />
              </div>
              <div className="location-details">
                <h2>Location Details</h2>
                <p>
                  <strong>City:</strong> {location.city}
                </p>
                <p>
                  <strong>Address:</strong> {location.formatted_address}
                </p>
                <p>
                  <strong>Zip Code:</strong> {location.zip}
                </p>
                <p>
                  <strong>Rating:</strong> {location.rating}
                </p>
                <p>
                  <strong>Wheelchair Accessible:</strong>{" "}
                  {location.wheelchair_accessible ? "Yes" : "No"}
                </p>
              </div>
            </div>
            <div className="location-hours">
            <h2>Opening Hours</h2>
              <ul>
                {location.opening_hours_periods.map((period, index) => (
                  <li key={index}>
                    <strong>{getDayName(period.open.day)}:</strong> {formatTime(period.open.time)} - {formatTime(period.close.time)}
                  </li>
                ))}
              </ul>
            </div>
            <div className="location-reviews">
              <h2>Reviews</h2>
              <ul>
                {location.reviews.map((review, index) => (
                  <li className='review-item' key={index}>
                    <h3>{review.author_name}</h3>
                    <p>{review.text}</p>
                    <div className="review-rating">{mapRatingToStars(review.rating)}</div>
                  </li>
                ))}
              </ul>
            </div>
            
            <div className="location-nearby">
              <h2>Nearby Transportation Stops</h2>
              <Slider {...settings}>
                {transportationStops.map((stop, index) => (
                  <div key={index} className="mini-card">
                    <MiniCard name={stop.name} imageUrl={stop.first_photo.photo_reference} route={`/stops/${stop.id}`}/>
                  </div>
                ))}
              </Slider>
            </div>

            {/* Nearby Zip Codes */}
            <div className="location-nearby">
              <h2>Nearby Zip Codes</h2>
              <Slider {...settings}>
                {zipCodes.map((zipcode, index) => (
                  <div key={index} className="mini-card">
                    <MiniCard name={zipcode.postal_code} imageUrl={zipcode.photo_reference_1} route={`/zipcodes/${zipcode.id}`}/>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default LocationInstance;

