import React, { useState, useEffect } from 'react';
import ZipcodeCard from '../components/ZipcodeCard';
import LocationCard from '../components/LocationCard';
import TransportationCard from '../components/TransportationCard';
import './GeneralSearch.css';
import axios from 'axios';
import LoadingAnimation from './LoadingAnimation';
import { useLocation } from 'react-router-dom';

function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

function highlightText(text, highlight) {
  const strText = String(text);
  const safeHighlight = escapeRegExp(highlight);
  const parts = strText.split(new RegExp(`(${safeHighlight})`, 'gi'));
  return (
    <>
      {parts.map((part, i) =>
        part.toLowerCase() === highlight.toLowerCase() ? (
          <span key={i} className="highlight">{part}</span>
        ) : (
          part
        )
      )}
    </>
  );
}

const tabs = {
  ZIPCODES: 'Zip Codes',
  LOCATIONS: 'Locations',
  TRANSPORTATION_STOPS: 'Transportation Stops',
};

const GeneralSearch = () => {
  const [zipcodesData, setZipcodesData] = useState([]);
  const [locationsData, setLocationsData] = useState([]);
  const [transportationStopsData, setTransportationStopsData] = useState([]);
  const [activeTab, setActiveTab] = useState(tabs.LOCATIONS);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const query = searchParams.get('query');

  useEffect(() => {
    const fetchAllData = async () => {
      setLoading(true);
      try {
        let [zipcodesResponse, locationsResponse, transportationResponse] = await Promise.all([
          axios.get(`https://api.austin-wheels.me/zipcodes?search=${encodeURIComponent(query)}`),
          axios.get(`https://api.austin-wheels.me/locations?search=${encodeURIComponent(query)}`),
          axios.get(`https://api.austin-wheels.me/transport?search=${encodeURIComponent(query)}`)
        ]);
        setZipcodesData(zipcodesResponse.data);
        setLocationsData(locationsResponse.data);
        setTransportationStopsData(transportationResponse.data);
      } catch (error) {
        console.error("There was an error fetching the data:", error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchAllData();
  }, [query]);

  const renderCards = () => {
    switch (activeTab) {
      case tabs.ZIPCODES:
        return zipcodesData.map(zipData => {
          const mappedZipcodeData = {
            zipCode: highlightText(zipData.postal_code, query),
            city: highlightText(zipData.city, query),
            county: highlightText(zipData.province, query),
            id: zipData.id,
            population: 'Not Available', 
            landArea: 'Not Available',
            wheelchairAccessibleLocations: highlightText(zipData.location_count, query),
            wheelchairFriendlyTransportStops: highlightText(zipData.stop_count, query),
            page: `/zip/${zipData.postal_code}`,
            backgroundImageURL: zipData.photo_reference_1,
          };
          return <ZipcodeCard key={zipData.id} zipcodeData={mappedZipcodeData} />;
        });
      case tabs.LOCATIONS:
        return locationsData.map(location => {
          const mappedLocationData = {
            name: highlightText(location.name, query),
            zipCode: highlightText(location.zip, query),
            city: highlightText(location.city, query),
            locationType: highlightText(location.types.join(", "), query),
            rating: highlightText(location.rating, query),
            id: location.id,
            backgroundImageUrl: location.first_photo.photo_reference,
          };
          return <LocationCard key={location.id} locationData={mappedLocationData} />;
        });
      case tabs.TRANSPORTATION_STOPS:
        return transportationStopsData.map(transportation => {
          const mappedTransportationData = {
            stop: highlightText(transportation.name, query),
            zipCode: highlightText(transportation.zip, query),
            city: highlightText(transportation.city, query),
            type: highlightText(transportation.types.join(", "), query),
            rating: highlightText(transportation.rating, query),
            backgroundImageUrl: transportation.first_photo.photo_reference,
            id: transportation.id,
            page: `/transport/${transportation.place_id}`,
          };
          return <TransportationCard key={transportation.place_id} transportationData={mappedTransportationData} />;
        });
      default:
        return null;
    }
  };

  if (error) {
    return (
      <div className='page'>
        <p className="error-text" style={{ paddingTop: '30px', fontSize: '30px'}}>
          Error: Unable to fetch data from the API.
        </p>
      </div>
    );
  }

  if (loading) {
    return <LoadingAnimation/>;
  }

  return (
    <div className='page'>
      <div className='tabs'>
        {Object.values(tabs).map((tabName) => (
          <button
            key={tabName}
            onClick={() => {
              console.log("Current activeTab: ", activeTab);
              console.log(`Attempting to change activeTab to: ${tabName}`);
              setActiveTab(tabName);
            }}
            className={activeTab === tabName ? 'active' : ''}
          >
            {tabName}
          </button>
        ))}
      </div>
      <p className="title-text">{activeTab}</p>
      <p>Displaying results for "{query}"</p>
      <div className='cards-container'>
        {renderCards()}
      </div>
    </div>
  );
};

export default GeneralSearch;

