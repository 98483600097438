import React, { useState, useEffect } from 'react';
import './Visuals.css'
import StopsPerZip from "../components/Visualizations/StopsPerZip.js";
import LocationsPerZip from "../components/Visualizations/LocationsPerZip.js";
import StopsRating from "../components/Visualizations/StopsRatings.js";
//import {PieChart} from "@d3/pie-chart-component"
import * as d3 from "d3";
import ChartPhoto from "./chart.png"

export default function Visuals() {


    const [v2_data, setV2Data] = useState([]);
    
    useEffect(() => {
        const fetchData = async () => {
        try {
            const response = await fetch("https://api.austin-wheels.me/zipcodes");
            const data = await response.json();
    
            // Assuming your API response has properties locationName and locationCount
            const formattedData = data.map((location) => ({
                xvalue: location.postal_code,
                count: location.stop_count,
            }));
            setV2Data(formattedData);
            console.log(v2_data);
            console.log("API Response", data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
        };
    
        fetchData();
    }, []); // Empty dependency array to run only once when the component mounts

    return (
        <div className="visuals">

            <h1>Visualizations</h1>

            <h3>Number of Locations per Zip Code</h3>
            <div className="graph">
            <LocationsPerZip/>
            </div>

            <h3 style={{ display: 'flex', justifyContent: 'center', paddingTop: '5%', paddingBottom: '1%' }}>Number of Stops per Zip Code</h3>
            <StopsPerZip style={{ display: 'flex', justifyContent: 'center', paddingLeft: '5%', paddingRight: '5%'}} data={v2_data}/>

            <h3>Rating of Each Stop</h3>
            <div className="graph">
            <StopsRating/>
            </div>
            
        </div>
    )
}


