import React, { useState } from 'react';
import './Card.css'; // Import CSS file
import { Link } from 'react-router-dom'

const TransportationCard = ({ transportationData }) => {
  const {
    stop,
    zipCode,
    city,
    rating,
    daysOpen,
    type,
    page,
    id,
    backgroundImageUrl, // URL to the background image
  } = transportationData;
  const route = `/stops/${id}`;
  const [imageError, setImageError] = useState(false);
  const defaultImageUrl = 'https://webstockreview.net/images/location-clipart-geolocation-2.png'; // Replace with your default image URL
  const handleImageError = () => {
    setImageError(true);
  };
  return (
    <div className="card-container">
      <Link to={route} className="hover-color"> 
        <div className="card-details">
          <img
            className='card-img'
            src={imageError ? defaultImageUrl : backgroundImageUrl}
            alt='image'
            onError={handleImageError} // Set the onError event handler
          />
          <h3><strong>{stop}</strong></h3>
          <p><strong>Zip Code:</strong> {zipCode}</p>
          <p><strong>City:</strong> {city}</p>
          <p><strong>Type:</strong> {type}</p>
          <p><strong>Rating:</strong> {rating}</p>
          <div className="learn-more-btn">More...</div>
        </div>
      </Link>
    </div>
  );
};

export default TransportationCard;
