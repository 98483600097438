import React from 'react';
import './Page.css';
import FadeLoader from 'react-spinners/FadeLoader'

const LoadingAnimation = () => (
    <div className="loadingScreen">
        <FadeLoader color={"#007bff"} height={25} width={6} margin={10}/>
    </div>
);

export default LoadingAnimation;