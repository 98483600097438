// Code inspired by Gallery Guide project https://gitlab.com/swe-spring-2023/art-project/
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Card, Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import saransh from '../images/saransh.png';
import ethan from '../images/ethan.png';
import silas from '../images/silas.jpg';
import bidhan from '../images/bidhan.jpg';
import fernando from '../images/fernando.jpg';

const team = [
    {
        name: "Saransh Raina",
        gitlab_username: "sraina11",
        role: "Backend",
        bio: "I'm a junior Computer Science major who enjoys working on fun projects and playing videogames.",
        tests: 7,
        email: "sraina1@utexas.edu",
        image: saransh
    },
    {
        name: "Ethan S Lopez",
        gitlab_username: "ethanslopez",
        role: "Frontend",
        bio: "I'm a junior computer science major that enjoys tech and watching college football.",
        tests: 7,
        email: "ethanslopez@utexas.edu",
        image: ethan
    },
    {
        name: "Bidhan Devkota",
        gitlab_username: "Bidhan123",
        role: "Backend",
        bio: "I'm a junior Computer Science major that enjoys playing soccer and playing video games! ",
        tests: 12,
        email: "bid.devkota@gmail.com",
        image: bidhan
    },
    {
        name: "Fernando Salas",
        gitlab_username: "frsalas513",
        role: "Frontend",
        bio: "I'm a junior Computer Science Major. I like to climb and I really enjoy listening to music.",
        tests: 7,
        email: "frsalas513@gmail.com",
        image: fernando
    },
    {
        name: "Minh-Triet (Silas) Pham",
        gitlab_username: "mtnp",
        role: "Frontend",
        bio: "I am a senior Computer Science major who enjoys making music, skateboarding, and cutting hair.",
        tests: 8,
        email: "silas@utexas.edu",
        image: silas
    },
]

const tools = [
    {
        name: "React",
        description: "JavaScript framework for frontend development",
        image: "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse3.mm.bing.net%2Fth%3Fid%3DOIP.a_wOSqpiu-wR6KEOKE1E0wAAAA%26pid%3DApi&f=1&ipt=ee3b1db5c464daf81e8ba7ecf7a344b040d2d89c4fdcf612b451d55d9eeab701&ipo=images",
        link: "https://reactjs.org"
    },
    {
        name: "GitLab",
        description: "Storing code and running CI/CD",
        image: "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fcdn.freebiesupply.com%2Flogos%2Flarge%2F2x%2Fgitlab-logo-png-transparent.png&f=1&nofb=1&ipt=0396643c2b70b4f5f1b1d1b433560c30e0feb02c449b7770b78fcca08c9a1535&ipo=images",
        link: "https://gitlab.com"
    },
    {
        name: "React Bootstrap",
        description: "CSS framework",
        image: "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fwww.kindpng.com%2Fpicc%2Fm%2F27-278320_bootstrap-logo-logo-png-bootstrap-logo-transparent-png.png&f=1&nofb=1&ipt=11da922f4efd21494fdec1ead65e9e7781b89fdc27c68f164a74897235947b54&ipo=images",
        link: "https://react-bootstrap.github.io/"
    },
    {
        name: "Postman",
        description: "For designing, testing, and documenting APIs",
        image: "https://external-content.duckduckgo.com/iu/?u=http%3A%2F%2Fww1.prweb.com%2Fprfiles%2F2018%2F10%2F05%2F15812110%2Fpostman-logo-vert-2018.png&f=1&nofb=1&ipt=a666d1715184b008c9d7b389cc891cf83af9e067d90afb08bdef7bbf685bcbd8&ipo=images",
        link: "https://www.postman.com/"
    }
]

const sources = [
    {
        name: "Google Places",
        description: "API used for gathering zip code and some location data",
        image: "https://spiralking.com/wp-content/uploads/2019/04/google-place.jpg",
        link: "https://developers.google.com/maps/documentation/places/web-service"
    },
    {
        name: "Transit API",
        description: "API used for gathering wheelchair accessible transportation data",
        image: "https://api-doc.transitapp.com/transit-logotype_iOS-dark.png",
        link: "https://transitapp.com/apis"     
    },
    {
        name: "WheelMap API",
        description: "API used for gathering wheelchair friendly locations data",
        image: "https://atlasofthefuture.org/wp-content/uploads/2021/01/Wheelmap-website.jpg",
        link: "https://wheelmap.org/search"     
    },
]

const names =   {"Saransh Raina": 0,
                "Ethan S Lopez": 1,
                "Bidhan Devkota": 2, 
                "Fernando Salas": 3, 
                "Minh-Triet (Silas) Pham": 4};

const client = axios.create({
    baseURL: 'https://gitlab.com/api/v4/projects/50495251',
  });
  
export default function About() {
    const [commitsState, updateCommits] = useState([0, 0, 0, 0, 0]);
    const [issuesState, updateIssues] = useState([0, 0, 0, 0, 0]);
    const [testsState, updateTests] = useState([0, 0, 0, 0, 0]);

    useEffect(() => {
        let newCommits = [0, 0, 0, 0, 0];
        client.get("repository/contributors")
        .then((response) => {
            response.data.forEach((element) => {
                const { name, email, commits } = element;
                team.forEach((member) => {
                    if (member.name === name || member.email === email 
                        || member.gitlab_username === name) {
                        newCommits[names[member.name]] += commits;
                    }
                });
            });
            updateCommits(newCommits);
        });
        let newIssues = [0, 0, 0, 0, 0];
        team.forEach((member) => {
            client.get("issues_statistics?assignee_username=" + member.gitlab_username)
            .then((response) => {
                newIssues[names[member.name]] += response.data['statistics']['counts']['closed'];
            })
        })
        updateIssues(newIssues);
      }, []);
    
    return(
        <Container>
            <h1 className="title-text">About Us</h1>
            <h2>Purpose and Results</h2>
            <p>Austin Wheels is a project to serve the wheelchair bound community in the Austin Area. 
              Our site will provide information about wheelchair accessible locations and transporation 
              options in the Greater Austin Area for any wheelchair bound individuals.</p>
            <p> The project dealt with integrating disparate data from multiple sources, such as Google,
                TransitAPI, and OpenAPI and so we had to be careful with overlapping data to ensure
                information stayed as accurate as possible.
            </p>
            <p> <a href='https://gitlab.com/sraina11/austin-wheels'>Gitlab Repo </a></p>
            <h1>Members</h1>
            <Row>
                {
                    team.map((member) => {
                        return (
                            <Col md="auto">
                                <Card
                                    bg={'dark'}
                                    text={'white'}
                                    style={{ width: '15rem', height: '50rem' }}>
                                    <Card.Img variant="top" src={member.image} />
                                    <Card.Body>
                                        <Card.Title>{member.name}</Card.Title>
                                        <Card.Subtitle>{"Role: " + member.role}</Card.Subtitle>
                                        <Card.Text>{"Gitlab ID: " + member.gitlab_username}</Card.Text>
                                        <Card.Text>{"About me: " + member.bio}</Card.Text>
                                        <Card.Text>{"Commits: " + commitsState[names[member.name]]}</Card.Text>
                                        <Card.Text>{"Issues: " + issuesState[names[member.name]]}</Card.Text>
                                        <Card.Text>{"Unit Tests: " + member.tests}</Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        )
                    })
                }
            </Row>
            <h2 className="d-flex justify-content-center p-4"> Total Stats</h2>
            <Row>
                <Col className="d-flex justify-content-center">
                    <h3>Total Commits: {commitsState.reduce((a, b) => a + b, 0)}</h3>
                </Col>
                <Col className="d-flex justify-content-center">
                    <h3>Total Issues: {issuesState.reduce((a, b) => a + b, 0)}</h3>
                </Col>
                <Col className="d-flex justify-content-center">
                    <h3>Total Tests: {testsState.reduce((a, b) => a + b, 0)}</h3>
                </Col>
            </Row>
            <h2 className="d-flex justify-content-center p-5"> Tools Used</h2>
            <Row>
                {
                    tools.map((tool) => {
                        return(
                            <Col md = "auto">
                            <Card
                                bg={'white'}
                                text={'dark'}
                                style={{ width: '16rem', margin: "1rem", height: '28rem'}}>
                                <Card.Img variant="top" src={tool.image} />
                                <Card.Body>
                                    <Card.Title>{tool.name}</Card.Title>
                                    <Card.Text>{tool.description}</Card.Text>
                                    <Link to={tool.link + ""}>{tool.link}</Link>
                                </Card.Body>
                            </Card>
                        </Col>
                        )
                    })
                }
              </Row>
            <h2>Postman Documentation</h2>
            <p>In order to make use of our API, check out our Postman documentation&nbsp; 
              <a href="https://documenter.getpostman.com/view/29992579/2s9YJZ3Po1" target={'_blank'} rel={'noreferrer'}>here</a>!</p>
            <h3 className="d-flex justify-content-center p-4"> Data Sources</h3>
            <Row>
                {
                    sources.map((source) => {
                        return(
                            <Col md = "auto">
                            <Card
                                bg={'white'}
                                text={'dark'}
                                style={{ width: '22rem', margin: "2rem", height: '35rem'}}>
                                <Card.Img variant="top" src={source.image} />
                                <Card.Body>
                                    <Card.Title>{source.name}</Card.Title>
                                    <Card.Text>{source.description}</Card.Text>
                                    <Link to={source.link + ""}>{source.link}</Link>
                                </Card.Body>
                            </Card>
                        </Col>
                        )
                    })
                }
              </Row>
            
        </Container>
    );
}