import React from 'react'
import { Link } from 'react-router-dom'
import "./LandingPage.css"

const LandingPage = () => {
  return (
    <div>
        <div className='landing-main'>
          <div className='landing-contents'>
              <h1>Welcome to AustinWheels</h1>
              <h3>Your Guide to Accessibility in the Greater Austin Area</h3>
              <p>AustinWheels is a community-driven initiative designed to empower individuals with limited mobility. We're committed to fostering inclusivity by providing the information you need to navigate Austin comfortably and confidently.</p>
              <Link to='About' className="hover-color"><button>Learn More</button></Link>
          </div>
        </div>
        <div className='overlay'></div>
    </div>
  )
}

export default LandingPage