import React, { useEffect, useRef, useState } from "react";
import * as Plot from "@observablehq/plot";
import "./GraphStyle.css";

export default function CountyBarGraph() {
  const [counties, setCounties] = useState([]);

    const plotRef = useRef();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch("https://api.californiawildfires.me/allCounties");
                const data = await response.json();
                setCounties(data);
                console.log(data)
            } catch (error) {
                console.error("Error fetching county data:", error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
      if (counties.length > 0) {
          const graph = Plot.plot({
              x: {grid: true},
              y: {grid: true},
              color: {legend: true},
              marks: [
                  Plot.ruleY([0]),
                  Plot.ruleX([0]),
                  Plot.dot(counties, {
                      x: "pop_density",
                      y: "fireMAR",
                      tip: true,
                      r: 4,
                      fill: "pop"
                  })
              ]
          })

          plotRef.current.append(graph)
          console.log("Graph created:", graph);

          return () => {
              graph.remove();
          };
      }
  }, [counties]);

  return (
    <div ref={plotRef} className="BarGraph"/>
  );
};
