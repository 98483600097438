import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import logo from '../images/logoaw.png';
import './Navbar.css';

const Navbar = () => {
  const [searchInput, setSearchInput] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    // Ensure the search input is not empty
    if (searchInput.trim()) {
      // Redirect to the GeneralSearch component with a query parameter
      navigate(`/GeneralSearch?query=${encodeURIComponent(searchInput)}`);
      setSearchInput(''); // Clear the search input after submission
    }
  };

  return (
    <div className='full-navbar'>
      <nav>
        <ul className='navbar'>
          <div className='logo'>
            <Link to='/'> 
              <img src={logo} alt="Logo" className="logo" />
            </Link>
          </div>
          <li className={location.pathname === '/' ? 'active' : ''}>
            <Link to='/' className="hover-color">Home</Link>
          </li>
          <li className={location.pathname === '/About' ? 'active' : ''}>
            <Link to='/About' className="hover-color">About</Link>
          </li>
          <li className={location.pathname === '/Zip' ? 'active' : ''}>
            <Link to='/Zip' className="hover-color">Zip Codes</Link>
          </li>
          <li className={location.pathname === '/Locations' ? 'active' : ''}>
            <Link to='/Locations' className="hover-color">Locations</Link>
          </li>
          <li className={location.pathname === '/Stops' ? 'active' : ''}>
            <Link to='/Stops' className="hover-color">Transportation</Link>
          </li>
          <li className={location.pathname === '/Visuals' ? 'active' : ''}>
            <Link to='/Visuals' className="hover-color">Visualizations</Link>
          </li>
          {/*<li className={location.pathname === '/ProviderVisuals' ? 'active' : ''}>
            <Link to='/ProviderVisuals' className="hover-color">Provider Visualizations</Link>
          </li>*/}

          {/* Search bar form */}
          <div className='search-container'>
            <form onSubmit={handleSearchSubmit}>
              <input 
                type='text' 
                placeholder='Search...' 
                className='search-input'
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
              />
              <button type='submit' className='search-button'>Go</button>
            </form>
          </div>
        </ul>
      </nav>
    </div>
  );
}

export default Navbar;
