import React, { useEffect, useRef, useState } from "react";
import * as Plot from "@observablehq/plot";
import "./GraphStyle.css";

export default function WildfireCostGraph() {
    const [fires, setFires] = useState([]);

    const plotRef = useRef();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch("https://api.californiawildfires.me/allWildfires");
                const data = await response.json();
                setFires(data);
                console.log(data)
            } catch (error) {
                console.error("Error fetching wildfire data:", error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        if (fires.length > 0) {
            const graph = Plot.plot({
                x: {grid: true},
                y: {grid: true, label: "cost to date"},
                color: {legend: true},
                marks: [
                    Plot.ruleY([0]),
                    Plot.ruleX([0]),
                    Plot.dot(fires, {
                        x: "size",
                        y: "cost_to_date",
                        tip: true,
                        r: 4,
                        fill: "size"
                    })
                ]
            })

            plotRef.current.append(graph)
            console.log("Graph created:", graph);

            return () => {
                graph.remove();
            };
        }
    }, [fires]);

    return (<div ref={plotRef} classname='BarGraph'/>);
}