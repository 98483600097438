import React, { useState, useEffect } from 'react';
import ZipcodeCard from '../components/ZipcodeCard';
import './Page.css';
import axios from 'axios';
import LoadingAnimation from './LoadingAnimation';

function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

function highlightText(text, highlight) {
  const strText = String(text);
  const safeHighlight = escapeRegExp(highlight);
  const parts = strText.split(new RegExp(`(${safeHighlight})`, 'gi'));
  return (
    <>
      {parts.map((part, i) =>
        part.toLowerCase() === highlight.toLowerCase() ? (
          <span key={i} className="highlight">{part}</span>
        ) : (
          part
        )
      )}
    </>
  );
}

const ZipCodes = () => {
  const [zipCodes, setZipCodes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null); 
  const [openDropdown, setOpenDropdown] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(() => {
    return parseInt(localStorage.getItem('currentPage')) || 1;
  });
  const [searchInput, setSearchInput] = useState('');
  const [submitSearch, setSubmitSearch] = useState('');
  const handleSearchSubmit = (event) => {
    event.preventDefault();
    if (searchInput.trim()) {
      setSubmitSearch(searchInput);
      setSearchInput('');
    }
  };
  const [filter, setFilter] = useState('');
  const [sortZip, setSortZip]= useState("Off");
  const [sortWAL, setSortWAL]= useState("Off");
  const [sortWATS, setSortWATS]= useState("Off");
  const itemsPerPage = 10; 
  const [dropdownOptions, setDropdownOptions] = useState({
    city: ["Bastrop", "Burnet", "Dripping Springs", "Elgin", "Kyle", "Taylor", "Cedar Park", "Georgetown", "Pflugerville", "Hutto", "Jarrel", "Leander", "Liberty Hill",
    "Lockhart", "Marble Falls", "Manor", "San Marcos", "Buda", "Round Rock", "Austin"],
    county: ["Bastrop", "Burnet", "Hays", "Williamson", "Travis", "Caldwell"],
    zip: ['On', 'Off'],
    wheelchair_Accessible_Locations: ['On', 'Off'],
    wheelchair_Friendly_Transport_Stops: ['On', 'Off']
  });
  useEffect(() => {
    let request = "https://api.austin-wheels.me/zipcodes";
    console.log(submitSearch);
    if (filter != '' || submitSearch != '') {
      if (submitSearch != '') {
        request = "https://api.austin-wheels.me/zipcodes?search=" + submitSearch;
      } else {
        request = "https://api.austin-wheels.me/zipcodes?search=" + filter;
      }
      if (sortZip === 'On') {
        request += "&sort=zip";
      } else if (sortWAL === 'On') {
        request += "&sort=location_count"
      } else if (sortWATS === 'On') {
        request += "&sort=stop_count"
      }
    } else {
      if (sortZip === 'On') {
        request = "https://api.austin-wheels.me/zipcodes?sort=zip";
      } else if (sortWAL === 'On') {
        request = "https://api.austin-wheels.me/zipcodes?sort=location_count"
      } else if (sortWATS === 'On') {
        request = "https://api.austin-wheels.me/zipcodes?sort=stop_count"
      }
    }

    axios.get(request)
      .then(response => {
        setZipCodes(response.data);
        setDropdownOptions(prevOptions => ({
          ...prevOptions,
        }));
        setLoading(false);
      })
      .catch(error => {
        console.error("There was an error fetching zipcodes:", error);
        setError(error);
        setLoading(false);
      });
  }, [filter, sortZip, sortWAL, sortWATS, submitSearch]);

  
  useEffect(() => {
    localStorage.setItem('currentPage', currentPage.toString());
  }, [currentPage]);

  
  if (error) {
    return (
      <div className='page'>
        <p className="error-text" style={{ paddingTop: '30px', fontSize: '30px'}}>
          Error: Unable to fetch data from the API.</p>
      </div>
    );
  }

  const indexOfLastZipCode = currentPage * itemsPerPage;
  const indexOfFirstZipCode = indexOfLastZipCode - itemsPerPage;
  const currentZipCodes = zipCodes.slice(indexOfFirstZipCode, indexOfLastZipCode);
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  

  const handleDropdownClick = (option) => {
    setIsDropdownOpen((prevState) => !prevState);
    setOpenDropdown(openDropdown === option ? null : option);
  };

  const handleSortChange = (e, v) => {
    if (e === "city" || e === "county") {
      setFilter(v);
      setSubmitSearch('');
    } else if (e === "zip"){
      setSortZip(v);
      setSortWAL("Off");
      setSortWATS("Off");
    } else if (e === "wheelchair_Accessible_Locations") {
      setSortWAL(v);
      setSortZip("Off");
      setSortWATS("Off");
    } else if (e === "wheelchair_Friendly_Transport_Stops") {
      setSortWATS(v);
      setSortZip("Off");
      setSortWAL("Off");
    }
  };

  const generatePaginationButtons = () => {
    const totalPages = Math.ceil(zipCodes.length / itemsPerPage);
    const pageButtons = [];
    const maxButtonsToShow = 10; 
    if (currentPage > 1) {
      pageButtons.push(
        <button
          key="prev"
          className="prev-next-button"
          onClick={() => handlePageChange(currentPage - 1)}
        >
          Prev
        </button>
      );
    }
    const startPage = Math.max(1, Math.min(currentPage - Math.floor(maxButtonsToShow / 2), totalPages - maxButtonsToShow + 1));
    const endPage = Math.min(totalPages, startPage + maxButtonsToShow - 1);
    for (let page = startPage; page <= endPage; page++) {
      pageButtons.push(
        <button
          key={page}
          className={`page-button ${currentPage === page ? 'current-page' : ''}`}
          onClick={() => handlePageChange(page)}
        >
          {page}
        </button>
      );
    }
    if (currentPage < totalPages) {
      pageButtons.push(
        <button
          key="next"
          className="prev-next-button"
          onClick={() => handlePageChange(currentPage + 1)}
        >
          Next
        </button>
      );
    }
    return pageButtons;
  };

  if (loading) {
    return <LoadingAnimation/>
  }
  
  return (
    <div className='page'>
      <div className='search-container'>
        <form onSubmit={handleSearchSubmit}>
            <input 
              type='text' 
              placeholder='Search...' 
              className='search-input'
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
          <button type='submit' className='search-button'>Go</button>
        </form>
      </div>
      <div className="dropdown-container">
        {Object.keys(dropdownOptions).map((option) => (
          <div className="dropdown" key={option}>
            <div className={`dropdown-button ${openDropdown === option ? 'open' : ''}`} onClick={() => handleDropdownClick(option)}>
              {(option.charAt(0).toUpperCase() + option.slice(1)).replace(/_/g, ' ')} {openDropdown === option && isDropdownOpen ? '▲' : '▼'}
            </div>
            {openDropdown === option && (
              <div className="dropdown-options">
                {dropdownOptions[option].map((value) => (
                  <div className="option" key={value} onClick={() => handleSortChange(option, value)}>
                    {value}
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
      <p className="title-text custom-title">Zip Codes</p>
      <p>Displaying {currentZipCodes.length} out of {zipCodes.length} results</p>
      <div className='page-container'>
        {currentZipCodes.map(zipData => {
          const mappedZipcodeData = {
            zipCode: highlightText(zipData.postal_code, submitSearch),
            city:highlightText(zipData.city, submitSearch),
            county: highlightText(zipData.province, submitSearch),
            id: zipData.id,
            population: 'Not Available',
            landArea: 'Not Available',
            wheelchairAccessibleLocations: highlightText(zipData.location_count, submitSearch),
            wheelchairFriendlyTransportStops: highlightText(zipData.stop_count, submitSearch),
            page: `/zip/${zipData.postal_code}`,
            backgroundImageURL: zipData.photo_reference_1
          };
  
          return <ZipcodeCard key={zipData.id} zipcodeData={mappedZipcodeData} />;
        })}
      </div>
      <div className="pagination">
        {generatePaginationButtons()}
      </div>
    </div>
  );
};

export default ZipCodes;
