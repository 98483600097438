import React from "react"
import './Visuals.css'
import WildfireCostGraph from "../components/Visualizations/WildfireCostGraph"
import CountyBarGraph from "../components/Visualizations/CountyBarGraph"
import FireAgencyMap from "../components/Visualizations/FireAgencyMap"

export default function Visuals() {

    return(
        <div className="visuals">

            <h1>Provider Visualizations</h1>

            <h3>Wildfire Cost & Size</h3>
            <div className="graph">
                <WildfireCostGraph/>
            </div>

            <h3>Population Density & fireMAR</h3>
            <div className="graph">
                <CountyBarGraph/>
            </div>

            <h3>Fire Protection Agencies Map</h3>
            <div className="graph">
                <FireAgencyMap/>
            </div>
            
        </div>
    )
}