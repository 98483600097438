import React, { useState } from 'react';
import './Card.css'; // Import CSS file
import { Link } from 'react-router-dom'


const  ZipcodeCard = ({ zipcodeData }) => {
    const {
        zipCode,
        city,
        county,
        population,
        landArea,
        wheelchairAccessibleLocations,
        wheelchairFriendlyTransportStops,
        page,
        backgroundImageURL,
        id
    } = zipcodeData;
    const [imageError, setImageError] = useState(false);
    const defaultImageUrl = 'https://webstockreview.net/images/location-clipart-geolocation-2.png'; // Replace with your default image URL
    const handleImageError = () => {
        setImageError(true);
    };
    const route = `/zipcodes/${id}`;
    return (
        <div className="card-container">
            <Link to={route} className="hover-color"> 
                <div className="card-details">
                    <img
                        className='card-img'
                        src={imageError ? defaultImageUrl : backgroundImageURL}
                        alt='image'
                        onError={handleImageError} // Set the onError event handler
                    />
                    <h3><strong>{zipCode}</strong></h3>
                    <p><strong>City:</strong> {city}</p>
                    <p><strong>County:</strong> {county}</p>
                    <p><strong>Wheelchair Accessible Locations:</strong> {wheelchairAccessibleLocations}</p>
                    <p><strong>Wheelchair Friendly Transport Stops:</strong> {wheelchairFriendlyTransportStops}</p>
                    <div className="learn-more-btn">More...</div>
                </div>
            </Link>
        </div>
    )
}
  
export default ZipcodeCard;